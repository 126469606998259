<template>
    <div>
        <div class="mask" v-show="isShow" @click="closeCheckoutLightbox(), CloseTab()"></div>
        <!-- 桌機版  -->
        <transition name="slide">
            <div class="checkout-lightbox" v-show="isShow">
                <template v-if="isCheckoutDataEmpty">
                    <div class="side-menu-wrap">
                        <form class="side-menu">
                            <div class="side-menu-close" @click="closeCheckoutLightbox(), CloseTab()">
                                <img src="@/assets/tgd/lightbox/close.svg" alt="" />
                            </div>
                            <div class="side-menu-top">
                                <div class="side-menu-item">
                                    <div class="title mb-3">服務資訊：
                                        <div class="qrcode_wrap" v-show="!isOnSiteRoom">
                                            <div
                                                class="d-inline-flex align-items-center justify-content-center qrcode_icon"
                                                @click.stop="inputCheck($event)"
                                            >
                                                <img
                                                    style="width: 20px;"
                                                    src="@/assets/tgd/index/qrcode.svg"
                                                    alt=""
                                                />
                                            </div>  
                                            <div 
                                                class="button-sms" 
                                                @click.stop="inputCheck($event)"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 20px;">
                                                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/>
                                                </svg>
                                            </div>
                                            <button
                                                class="btn-order"
                                                @click="handlePrintWhiteList"
                                            >
                                                <img
                                                    src="@/assets/tgd/customer/print.svg"
                                                    alt=""
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row form">
                                        <div class="col-5">
                                            <BaseInput
                                                title="會員電話"
                                                placeholder="請輸入"
                                                type="tel"
                                                class="form-control customer_phone_text"
                                                name="customer_phone"
                                                @blur="checkMember()"
                                                :disabled="submitType == 2"
                                            />
                                        </div>
                                        <div class="col-7">
                                            <BaseInput
                                                title="會員名稱"
                                                placeholder="請輸入"
                                                type="text"
                                                class="form-control customer_name_text"
                                                name="customer_name"
                                                :disabled="submitType == 2"
                                            />
                                        </div>
                                        <div class="col-5">
                                            <BaseInput
                                                title="開始時間"
                                                placeholder="請輸入"
                                                type="text"
                                                class="form-control"
                                                name="start_time"
                                                @inputBlurred="timeFormat"
                                                :disabled="submitType == 2"
                                            />
                                        </div>
                                        <div class="col-7">
                                            <BaseInput
                                                title="結束時間"
                                                placeholder="請輸入"
                                                type="text"
                                                class="form-control end-text"
                                                name="end_time"
                                                :disabled="submitType == 2"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="side-menu-item">
                                    <div class="title-group mb-3">
                                        <div class="title  flex-shrink-0 me-2">服務內容：</div>
                                        <span v-if="(submitType == 0 && orderId > 0) || submitType == 1" class="red">提醒：如果服務內容有變更，將不會判斷原先芳療師是否會服務項目及是否上鐘</span>
                                    </div>
                                    <div class="row form">
                                        <div class="col-5 position-relative">
                                            <BaseSelect
                                                title="芳療師"
                                                :options="aroma.list"
                                                name="aroma_select"
                                                :disabled="
                                                    !serviceIdList.length ||
                                                    submitType == 2 ||
                                                    checkoutData.data.answer_list.divide_class_member_id > 0
                                                "
                                                isShowInitVal
                                                isFilterSearch
                                            />
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-6 pe-1 position-relative">
                                                    <BaseSelect
                                                        title="房間"
                                                        :options="checkoutData.data.roomAry"
                                                        name="room_select"
                                                    />
                                                </div>
                                                <div class="col-6 ps-1 position-relative">
                                                    <BaseSelect
                                                        title="床號"
                                                        :options="bedList"
                                                        name="bed_select"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="side-menu-item" style="background: #d6d6d6; position: relative; z-index: 2">
                                    <div class="row form">
                                        <div class="col-5" v-show="submitType != 2">
                                            <div class="input-group mb-3">
                                                <button type="button" class="btn" @click="addService()">
                                                    <img src="@/assets/tgd/lightbox/add-button.svg" alt="" />
                                                    <span> 新增服務 </span>
                                                    <p></p>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <template v-for="(field, idx) in fields" :key="field.key">
                                                <div class="col-4">
                                                    <BaseSelect
                                                        title=""
                                                        :options="checkoutData.data.serviceAry"
                                                        @selectChange="changeService(idx)"
                                                        :name="`service[${idx}].service_id`"
                                                        :disabled="submitType == 2"
                                                        isFilterSearch
                                                    />
                                                </div>
                                                <div class="col-8">
                                                    <div
                                                        class="cash-wrap position-relative"
                                                        :style="`z-index:${100 - idx};`"
                                                    >
                                                        <BaseSelectInput
                                                            placeholder="請輸入"
                                                            :selectName="`service[${idx}].type`"
                                                            :inputName="`service[${idx}].txt`"
                                                            :options="[
                                                                {
                                                                    id: 1,
                                                                    name: '用劵',
                                                                },
                                                                {
                                                                    id: 2,
                                                                    name: '現金',
                                                                },
                                                            ]"
                                                            :disabled="submitType == 2"
                                                            @selectChange="(val)=> {
                                                                if(val == 1) return;
                                                                getTicketList()
                                                            }"
                                                            @txtInputBlur="getTicketList"
                                                        />
                                                    </div>
                                                    <label class="is-old">
                                                        <input
                                                            type="checkbox"
                                                            :checked="values.service[`${idx}`].is_old"
                                                            :false-value="0"
                                                            :true-value="1"
                                                            v-model="values.service[`${idx}`].is_old"
                                                            :disabled="submitType == 2"
                                                        />
                                                        <span>老點</span>
                                                    </label>
                                                    <div
                                                        class="cancel-img"
                                                        @click="remove(idx)"
                                                        v-show="submitType != 2"
                                                    >
                                                        <a href="javascript:;">
                                                            <img src="@/assets/tgd/lightbox/del.svg" alt="" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="side-menu-item" style="background: #d6d6d6; position: relative; z-index: 1">
                                    <div class="row form">
                                        <div class="col-5" v-show="submitType != 2">
                                            <div class="input-group mb-3">
                                                <button type="button" class="btn violation" @click="addExternal()">
                                                    <img src="@/assets/tgd/lightbox/add-button.svg" alt="" />
                                                    <span> 新增外部券服務 </span>
                                                    <p></p>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <template v-for="(field, idx) in fieldsExternal" :key="field.key">
                                                <div class="col-5 position-relative violation">
                                                    <BaseSelect
                                                        title=""
                                                        :options="externalList.data"
                                                        :name="`external[${idx}].external_id`"
                                                        :disabled="submitType == 2"
                                                        @selectChange="changeExternal(idx)"
                                                        isFilterSearch
                                                    />
                                                </div>
                                                <div class="col-7">
                                                    <BaseInput
                                                        title="券號"
                                                        placeholder="請輸入"
                                                        type="text"
                                                        class="form-control"
                                                        :name="`external[${idx}].external_number`"
                                                        :disabled="submitType == 2"
                                                    />
                                                    <div
                                                        class="cancel-img"
                                                        @click="removeExternal(idx)"
                                                        v-show="submitType != 2"
                                                    >
                                                        <a href="javascript:;">
                                                            <img src="@/assets/tgd/lightbox/del.svg" alt="" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-12"
                                                    v-for="(item, i) in values.external[idx].list"
                                                    :key="item.id"
                                                >
                                                    <div class="external-info-wrap">
                                                        <BaseInput
                                                            type="text"
                                                            class="form-control border-radius-all"
                                                            :value="item.name"
                                                            :name="`external[${idx}].list[${i}].name`"
                                                            :disabled="true"
                                                        />
                                                    </div>
                                                    <label class="is-old">
                                                        <input
                                                            type="checkbox"
                                                            :checked="values.external[idx].list[i].is_old"
                                                            :false-value="0"
                                                            :true-value="1"
                                                            v-model="values.external[idx].list[i].is_old"
                                                            :disabled="submitType == 2"
                                                        />
                                                        <span>老點</span>
                                                    </label>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="side-menu-bottom">
                                <div class="side-menu2-item">
                                    <div class="row sum">
                                        <div class="col-5">小計</div>
                                        <div class="col-7">
                                            $
                                            {{ numberFormat(smAmount) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="side-menu2-item">
                                    <div class="form">
                                        <div class="row">
                                            <div class="col-5">
                                                <BaseInput
                                                    title="券號"
                                                    placeholder="輸入折價券號"
                                                    type="text"
                                                    class="form-control"
                                                    name="discount_number_list[0]"
                                                    :disabled="submitType == 2"
                                                    @blur="verifyDiscountNum()"
                                                />
                                            </div>
                                            <div class="col-7 d-flex justify-content-between pe-3 mb-2">
                                                <div v-if="discountPrice.msg">{{ discountPrice.msg }}</div>
                                                <div v-else>{{ discountPrice.name }}</div>
                                                <div class="last-color">
                                                    {{ discountPrice.price ? `-${numberFormat(discountPrice.price)}` : 0 }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-5">特約</div>
                                            <div class="col-7">
                                                <BaseCalcSelectInput
                                                    selectDate="1"
                                                    selectStyle="width: 56%"
                                                    inputClass="last-color"
                                                    placeholder="請輸入"
                                                    selectName="special_select_name"
                                                    inputName="special_price"
                                                    v-model="checkoutData.data.answer_list.special_price"
                                                    :inputValue="checkoutData.data.answer_list.special_price"
                                                    :options="checkoutData.data.specialAry"
                                                    :disabled="submitType == 2"
                                                    :disableInput="true"
                                                    isFilterSearch
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-5">增補</div>
                                            <div class="col-7">
                                                <BaseCalcSelectInput
                                                    selectDate="1"
                                                    selectStyle="width: 56%"
                                                    placeholder="請輸入"
                                                    selectName="calc_select_name"
                                                    inputName="supplement"
                                                    :options="[
                                                        { id: 1, name: '+' },
                                                        { id: 2, name: '-' },
                                                    ]"
                                                    :disabled="submitType == 2"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-5">現金付款</div>
                                            <div class="col-7">
                                                <BaseCalcSelectInput
                                                    selectDate=""
                                                    inputClass="custom-radius"
                                                    placeholder="請輸入"
                                                    selectName="calc_select"
                                                    inputName="cash_payment"
                                                    v-model="checkoutData.data.answer_list.cash_payment"
                                                    :inputVal="checkoutData.data.answer_list.cash_payment"
                                                    :disabled="submitType == 2"
                                                />
                                            </div>
                                        </div>
                                        <div class="row no-gutters">
                                            <div class="col-5">剩餘款項</div>
                                            <div class="col-7">
                                                <div class="row no-gutters">
                                                    <div class="col-8 px-0">
                                                        <BaseSelect
                                                            title=""
                                                            placeholder="付款方式選擇"
                                                            rootClass="no-style mb-0"
                                                            iconColor="0f2a68"
                                                            isValueString
                                                            :options="paymentMethod"
                                                            name="pay_type"
                                                            :disabled="submitType == 2"
                                                        />
                                                    </div>
                                                    <div class="col-4 px-0 text-end fz-20 last-color">
                                                        ${{ numberFormat(finalNum) }}
                                                    </div>
                                                </div>
                                            </div>
                                            <template v-if="values.pay_type !== ''">
                                                <div class="col-5"></div>
                                                <div class="col-7">
                                                    <BaseInput
                                                        rootClass="authorization-input"
                                                        title="備註"
                                                        placeholder="請輸入備註"
                                                        type="text"
                                                        class="form-control"
                                                        name="authorization_code"
                                                        :disabled="submitType == 2"
                                                    />
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="side-menu2-item">
                                    <div class="form">
                                        <div class="row">
                                            <div class="col-5">統編</div>
                                            <div class="col-7">
                                                <BaseCalcSelectInput
                                                    selectDate=""
                                                    inputClass="custom-radius"
                                                    placeholder="請輸入"
                                                    selectName="select_editor"
                                                    inputName="input_editor"
                                                    :inputValue="checkoutData.data.answer_list.uniform_numbers"
                                                    :disabled="submitType == 2"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                checkoutData.data.store_data.is_two &&
                                                (submitType == 1 || submitType == 2)
                                            "
                                            class="row"
                                        >
                                            <div class="col-5">選擇要開立發票的公司</div>
                                            <div
                                                class="col-7 d-flex justify-content-end pe-3 mb-2"
                                                v-if="submitType == 2"
                                            >
                                                {{ checkoutData.data.answer_list.sale_uniform_numbers || '' }}
                                            </div>
                                            <div class="col-7 radio-wrap" v-else>
                                                <template
                                                    v-for="store_data in checkoutData.data.store_data.invoice_list"
                                                    :key="store_data.id"
                                                >
                                                    <BaseRadio
                                                        type="radio"
                                                        name="invoice_type"
                                                        :label="store_data.uniform_numbers"
                                                        :value="store_data.id"
                                                        v-model="invoice_type"
                                                    />
                                                </template>
                                            </div>
                                        </div>
                                        <div class="row pt-3" v-show="submitType != 2">
                                            <div class="col-5">
                                                <div class="input-group">
                                                    <button
                                                        type="button"
                                                        class="btn btn-block btn-cancel"
                                                        @click="closeCheckoutLightbox(), CloseTab()"
                                                    >
                                                        取消
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                class="col-7"
                                                v-if="(submitType == 0 && orderId == 0) || submitType == 3"
                                            >
                                                <div class="input-group">
                                                    <button
                                                        type="button"
                                                        class="btn btn-block btn-checkout"
                                                        @click="toSubmit"
                                                        :disabled="isFetching"
                                                    >
                                                        <span>開始服務</span>
                                                        <div class="price">
                                                            <span>NT$</span>
                                                            <strong>{{ numberFormat(finalNum) }}</strong>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="col-7" v-if="submitType == 1">
                                                <div class="input-group">
                                                    <button
                                                        type="button"
                                                        class="btn btn-block btn-checkout"
                                                        @click="toSubmit"
                                                        :disabled="isFetching"
                                                    >
                                                        <span>確認結帳</span>
                                                        <div class="price">
                                                            <span>NT$</span>
                                                            <strong>{{ numberFormat(finalNum) }}</strong>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="col-7" v-if="submitType == 0 && orderId > 0">
                                                <div class="input-group">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-block btn-checkout2"
                                                        @click="toSubmit"
                                                        :disabled="isFetching"
                                                    >
                                                        <span>儲存變更</span>
                                                        <div class="price">
                                                            <span>NT$</span>
                                                            <strong>{{ numberFormat(finalNum) }}</strong>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 只改房間 -->
                                        <div class="row pt-3" v-show="submitType == 2">
                                            <div class="col-5">
                                                <div class="input-group">
                                                    <button
                                                        type="button"
                                                        class="btn btn-block btn-cancel"
                                                        @click="closeCheckoutLightbox(), CloseTab()"
                                                    >
                                                        取消
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-7" v-if="submitType == 2 && orderId > 0">
                                                <div class="input-group">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-block btn-checkout2 justify-content-center"
                                                        @click="toSubmitRoom"
                                                    >
                                                        <span>儲存變更</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <MemberSideLightbox 
                        :customerData="checkoutData.data.answer_list"
                        :values="values"
                        :orderLocation="orderLocation" 
                        :submitType="submitType"
                        :sourceTag="checkoutData.data.source_tag"
                        :remarkTag="checkoutData.data.remark_tag"
                        :memoExternalTag="checkoutData.data.memo_external_tag"
                        :hideSave="submitType == 0 && orderId == 0 || hideSaveBtn == true"
                        @saveLocation="saveLocation"
                        ref="memberSide"
                    />
                </template>
            </div>
        </transition>
    </div>
    <QrCodeLightbox
        v-model:isShow="qrCode.isShow"
        :img="qrCode.img"
        :url="qrCode.url"
        :customerName="values.customer_name"
    />
</template>

<script>
import { ref, reactive, computed, watch, nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useForm, useFieldArray } from 'vee-validate';
import * as yup from 'yup';
import { basePost } from '@/js/services/baseService';
import useHandleLightboxOpen from '@/hooks/common/useHandleLightboxOpen';
import useDiscountPrice from '@/hooks/common/useDiscountPrice';
import { flashMessage } from '@/js/swal';
import useAromaList from "@/hooks/common/useAromaList";
import MemberSideLightbox from '@/components/lightbox/MemberSideLightbox';
import useOrderLocation from "@/hooks/common/useOrderLocation";
import { numberFormat, timeFormat } from "@/js/functions";
import Swal from "sweetalert2";
import SwalCustom from "@/hooks/common/useSwalCustom";
// 20231109
import QrCodeLightbox from "@/components/lightbox/QrCodeLightbox";

export default {
    name: 'CheckoutLightbox',
    components: {
        MemberSideLightbox,
        // 20231110 進行中訂單
        QrCodeLightbox
    },
    props: {
        orderId: {
            type: [Number, String],
            required: true,
        },
        submitType: {
            type: [Number, String],
            required: true,
        },
        reserveCheckOutData: {
            type: Object,
            required: false,
        },
        bedId: {
            type: Number,
            required: false,
            default: 0,
        },
        openBy: String,
    },
    setup(props) {
        //判斷是不是現場房況
        const isOnSiteRoom = ref(false);
        onMounted(() => {
            isOnSiteRoom.value = window.location.href.includes('/on_site_room');
        });

        const orderId = computed(() => props.orderId);
        const bedId = computed(() => props.bedId);
        const submitType = computed(() => props.submitType);
        const { state, dispatch } = useStore();
        const storeId = computed(() => state.auth.store_id).value;
        const isShow = computed(() => state.global.isCheckoutLightboxShow);
        const { openCheckoutLightbox, closeCheckoutLightbox } = useHandleLightboxOpen();
        const { getDiscountPrice } = useDiscountPrice();
        

        const memberSide = ref();
        const CloseTab = () => {
            memberSide.value.CloseTab();
        }

        let checkoutData = reactive({ data: {} });
        // 小計
        let smAmount = ref(0);

        // 剩餘款項
        let finalNum = ref(0);

        // form驗證設定
        const validationSchema = yup.object({
            customer_phone: yup
                .string()
                .trim('不可留空')
                .matches(/^[0-9]+$/, '請輸入數字')
                .required('必填'),
            customer_name: yup.string().required('必填'),
            start_time: yup
                .string()
                .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, '請輸入正確格式')
                .required('必填'),
            end_time: yup.string().required('必填'),
            aroma_select: yup.string().required('必填'),
            room_select: yup.string().required('必填'),
            bed_select: yup.string().required('必填'),
            special_select_name: yup.string(),
            special_price: yup.string().required('必填'),
            calc_select_name: yup.string().required('必填'),
            supplement: yup.string().required('必填'),
            cash_payment: yup.string().required('必填'),
            source: yup.string(),
            remark: yup.string(),
            memo_external: yup.string(),
            pay_type: yup.string().when('service', (service, schema) => {
                if (service && service.length && submitType.value == 1 && finalNum.value > 0) {
                    return schema.required('必填');
                }
                return schema;
            }),
            service: yup
                .array()
                .of(
                    yup.object({
                        service_id: yup.mixed().required('必填'),
                        type: yup.mixed().required('必填'),
                        txt: orderId.value ? yup.mixed().required('必填') : yup.mixed(),
                        is_old: yup.mixed(),
                    })
                )
                .strict(),
            authorization_code: yup.string().when('pay_type', (pay_type, schema) => {
                if (pay_type !== '' && submitType.value == 1 && finalNum.value > 0) {
                    return schema.required('必填');
                }
                return schema;
            }),
        });
        const { values, handleSubmit, isSubmitting, setFieldValue, resetForm } = useForm({
            validateOnMount: false,
            validationSchema,
        });
        const { remove, push, fields, replace } = useFieldArray('service');
        const {
            remove: removeExternal,
            push: pushExternal,
            fields: fieldsExternal,
            replace: replaceExternal,
        } = useFieldArray('external');

        const isFetching = ref(false);

        const customSwal = new SwalCustom({
            title: "請先填寫客戶來源!",
            text: "",
            icon: "error",
            confirmButtonText: "確定",
        });

        const toSubmit = handleSubmit(async (values, errors) => {
            
            const tagWrap = document.querySelector('.tag-wrap.px-3');
            const tags = tagWrap.querySelectorAll('.tag');
            const tagCount = tags.length;
            const isEmpty = document.querySelector('[name="source"]').value === '';

            if (tagCount == 0 && isEmpty) {
                customSwal.fire();
                return;
            }

            if(isFetching.value) return;
            
            if (!(values.service && values.service.length > 0) && !(values.external && values.external.length > 0)) {
                flashMessage('請選擇服務', 'error');
                return;
            }

            const serviceContent = mixService(values.service, values.external);

            let discountNumber = [];

            if (values.discount_number_list && values.discount_number_list[0] !== '') {
                discountNumber = values.discount_number_list;
            }

            if (orderLocationChangeNum.value > 1) {
                await saveLocation(false);
            }

            if(submitType.value == 3){
                saveLocation();   
            }

            isFetching.value = true
            const res = await basePost('/api_order/order_check', {
                store_id: storeId,
                order_id: submitType.value === 3 ? 0 : orderId.value,
                customer_phone: values.customer_phone,
                customer_name: values.customer_name,
                start_time: values.start_time,
                room_id: values.room_select,
                room_bed_id: values.bed_select,
                member_id: values.aroma_select,
                service_content: JSON.stringify(serviceContent),
                special_id: values.special_select_name || 0,
                special_price: values.special_price,
                supplement: values.calc_select_name == 1 ? values.supplement : -values.supplement,
                cash_payment: values.cash_payment,
                payment_method: values.pay_type || '',
                uniform_numbers: values.input_editor,
                source: values.source,
                remark: values.remark,
                memo_external: values.memo_external,
                // 0編輯，1結帳，2取消，3續訂
                order_type: submitType.value === 3 ? 0 : submitType.value,
                discount_number: JSON.stringify(discountNumber),
                authorization_code: values.authorization_code,
                invoice_type: values.invoice_type || 1,
                source_tag: JSON.stringify(values.source_tag),
                remark_tag: JSON.stringify(values.remark_tag),
                memo_external_tag: JSON.stringify(values.memo_external_tag),
                // 3續訂 才要傳
                renew_order_id: submitType.value === 3 ? orderId.value : null,
            });
            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                isFetching.value = false;
                
                // 新增訂單成功後回傳的order_id
                var return_order_id = data.res_data.order_id;
                var strengthen_parts = JSON.stringify(orderLocation.data);

                if (submitType.value == 0 && orderId.value != 0) {
                    flashMessage('編輯成功');
                }
                if (submitType.value == 0 && orderId.value == 0 || submitType.value == 3) {

                    const updateOrderLocation = async (showMessage = true) => {
                        const res = await basePost('/api_order/order_location_update', {
                            order_id: return_order_id,
                            location: strengthen_parts,
                        });
                        const { status, data } = res;

                        if (status === 200 && data.res_code === 1) {
                            if (!showMessage) return;
                            // flashMessage('新增訂單成功');
                            Swal.fire({
                                title: "新增訂單成功!",
                                icon: "success",
                                confirmButtonText: "確定",
                            })
                        }
                    };

                    updateOrderLocation();
                    
                }

                if (submitType.value == 1) {
                    // 訂單是否開立發票 0不開立 1開立
                    if (data.res_data.is_issue_invoice == 0) {
                        dispatch('ticket/getServiceDetailsHtml', orderId.value);
                        closeCheckoutLightbox();
                        return;
                    }

                    const secondPrint = originSecondPrint(orderId.value)
                    addEventListener('afterprint', secondPrint);
                    await dispatch('ticket/getInvoiceHtml', orderId.value);
                    function originSecondPrint(id) {
                        return function() {
                            removeEventListener('afterprint', secondPrint);
                            dispatch('ticket/getServiceDetailsHtml', id);
                        }
                    }
                }

                closeCheckoutLightbox();
            } else {
                switch (data.res_content) {
                    case '查無此票券':
                        flashMessage(data.res_content, 'error');
                        break;
                    case undefined:
                        flashMessage('發生錯誤', 'error');
                        break;
                    default:
                        flashMessage(data.res_content);
                        break;
                }
                isFetching.value = false
            }
        });

        const toSubmitRoom = handleSubmit(async (values, errors) => {
            const res = await basePost('/api_order/paid_update_order_room', {
                order_id: orderId.value,
                room_id: values.room_select,
                room_bed_id: values.bed_select,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                if (orderId.value != 0) {
                    flashMessage('編輯成功');
                }
                closeCheckoutLightbox();
            } else {
                switch (data.res_content) {
                    case undefined:
                        flashMessage('發生錯誤', 'error');
                        break;
                    default:
                        flashMessage(data.res_content);
                        break;
                }
            }
        });

        const hideSaveBtn = ref(false);

        // 取得結帳資料
        const getCheckOutData = async () => {
            let dataObj = {
                store_id: storeId,
                order_id: orderId.value,
                room_bed_id: bedId.value,
            };
            // 現場房況新增的時候api需多帶type:room_add
            if (props.openBy === 'onSiteRoom' && submitType.value == 0 && orderId.value == 0) {
                dataObj.type = 'room_add';
            }
            if (submitType.value === 3) {
                // 續訂
                dataObj = {
                    store_id: storeId,
                    order_id: 0,
                    renew_order_id: orderId.value,
                };
            }

            const res = await basePost('/api_order/order_details', dataObj);
            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                // console.log('data.res_data',data.res_data)
                if(data.res_data.answer_list.id == 0){
                    hideSaveBtn.value = true;
                    console.log('hideSaveBtn.value',hideSaveBtn.value)    
                }
                return data.res_data;
            }
        };

        // 外部券清單
        let externalList = reactive({ data: [] });
        // 取得外部券清單
        const getExternalList = async () => {
            const res = await basePost('/api_external/external_list', {
                store_id: state.auth.store_id,
            });
            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                const { res_data } = data;
                externalList.data = res_data;
            }
        };
        getExternalList();


        //設定結帳資料
        const setCheckoutData = async (data) => {
            checkoutData.data = data;

            const {
                // 會員電話
                customer_phone,
                // 會員名稱
                customer_name,
                // 開始時間
                start_time,
                // 結束時間
                end_time,
                // 特約價
                special_price,
                // 增補數
                supplement,
                // 現金付款
                cash_payment,
                remark,
                source,
                order_location,
                authorization_code,
                invoice_type,
            } = checkoutData.data.answer_list;

            orderLocation.data = order_location;

            // 芳療師選中的值
            let aroma_select = checkoutData.data.answer_list.member_id;

            // 房間選中的值
            let room_select = checkoutData.data.answer_list.room_id;

            // 床號選中的值
            let bed_select = checkoutData.data.answer_list.room_bed_id;

            // 增補選單選中的值
            let special_select_name = checkoutData.data.answer_list.special_id || 0;

            // 增補選單選中的值
            let calc_select_name = checkoutData.data.answer_list.supplement < 0 ? 2 : 1;

            // 付款方式選中的值
            let pay_type = checkoutData.data.answer_list.payment_method;

            let abs_supplement = Math.abs(checkoutData.data.answer_list.supplement);

            let discount_number_list = [];
            if (checkoutData.data.answer_list.discount_number_list.length > 0) {
                discount_number_list = checkoutData.data.answer_list.discount_number_list.map((itme) => {
                    discountPrice.price = itme.price;
                    discountPrice.name = itme.ticket_name;
                    return itme.ticket_number;
                });
            } else {
                discountPrice.price = 0;
                discountPrice.name = '';
            }

            //給值
            let initialValues = {
                customer_phone,
                customer_name,
                start_time,
                end_time,
                special_price,
                supplement: abs_supplement,
                cash_payment,
                aroma_select,
                room_select,
                bed_select,
                special_select_name,
                calc_select_name,
                pay_type,
                input_editor: checkoutData.data.answer_list.uniform_numbers
                    ? checkoutData.data.answer_list.uniform_numbers
                    : '',
                source: checkoutData.data.answer_list.source ? checkoutData.data.answer_list.source : '',
                remark: checkoutData.data.answer_list.remark ? checkoutData.data.answer_list.remark : '',
                memo_external: checkoutData.data.answer_list.memo_external ? checkoutData.data.answer_list.memo_external : '',
                discount_number_list,
                authorization_code: authorization_code || '',
                invoice_type: invoice_type || checkoutData.data.store_data.invoice_list[0].id,
            };

            Object.keys(initialValues).forEach((key) => {
                if (initialValues[key] !== '') {
                    setFieldValue(key, initialValues[key]);
                }
            });

            smAmount.value = checkoutData.data.answer_list.ticekt_salse_service_price;
            replace(checkoutData.data.answer_list.service || []);

            const serviceObj = checkoutData.data.serviceAry.reduce((prev, curr) => {
                return { ...prev, [curr.id]: curr.name };
            }, {});
            if (checkoutData.data.answer_list.external) {
                checkoutData.data.answer_list.external.forEach((item) => {
                    item.list.forEach((val) => {
                        val.name = serviceObj[val.service_id];
                    });
                });
            }
            replaceExternal(checkoutData.data.answer_list.external || []);
        };

        // 床號
        const bedList = computed(() => {
            if (!values.room_select) return [];
            return checkoutData.data.roomAry.find((room) => room.id == values.room_select).roomBedAry;
        });

        watch(
            () => values.room_select,
            (room_select, old_select) => {
                // 避開初始化
                if (!room_select || !old_select) return;
                // 如果換房間，床位則預設房間第一個床位
                values.bed_select = bedList.value[0].id;
            }
        );

        // 計算最終金額
        const countMoney = () => {
            let fnum = smAmount.value - values.special_price - discountPrice.price;

            if (values.calc_select_name == 2) {
                fnum = fnum - Math.abs(values.supplement);
            } else {
                fnum = fnum + Math.abs(values.supplement);
            }

            fnum = fnum - values.cash_payment;

            finalNum.value = fnum;
        };

        // 檢查有無更換會員
        const checkMember = async () => {
            const res = await basePost('/api_customer/customer_data', {
                store_id: storeId,
                customer_phone: values.customer_phone,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {

                if(data.res_data.name == ''){
                    values.customer_name = '貴賓姓名';
                }else{
                    values.customer_name = data.res_data.name;     
                }

                checkoutData.data.answer_list.remaining_tickets = data.res_data.remaining_tickets;
                checkoutData.data.answer_list.remark = data.res_data.remark;
                checkoutData.data.answer_list.source = data.res_data.source;
                checkoutData.data.answer_list.memo_external = data.res_data.memo_external;
                checkoutData.data.answer_list.consumptionNum = data.res_data.consumptionNum;
                checkoutData.data.answer_list.customerOrderAry = data.res_data.customerOrderAry;

                if (submitType.value == 0 && orderId.value == 0) {
                    const lastTimeOrderLocation =  data.res_data.order_location
                    for (let index = 1; index < 14; index++) {
                        if(!lastTimeOrderLocation[index]){
                            lastTimeOrderLocation[index] = []
                        }
                    }
                    orderLocation.data = lastTimeOrderLocation;
                }

                // console.log(data.res_data.is_black);
                // console.log(data.res_data.is_black === 1);
                // 黑名單確認
                if (data.res_data.is_black === 1) {
                    Swal.fire({
                        title: "此為黑名單!",
                        // icon: "error",
                        confirmButtonText: "關閉",
                    })
                }
            }
            
        };

        // 計算結束時間
        const countEndTime = async (need_count = 1) => {
            if (need_count) {
                const serviceContent = mixService(values.service, values.external);
                const res = await basePost('/api_order/calculate_all_services_time', {
                    store_id: storeId,
                    date: values.start_time,
                    service: JSON.stringify(serviceContent),
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    values.end_time = data.res_data.end_time;
                }
            }
        };

        const isLoading = ref(false);
        watch([orderId, submitType, isShow], (arr) => {
            if (!String(arr[0]) || !String(arr[1]) || !arr[2]) return;
            if (orderId.value >= 0 && isShow.value) {
                resetForm();
                isLoading.value = true;
                getCheckOutData().then((data) => {
                    setCheckoutData(data);
                    orderLocationChangeNum.value = 0;
                    nextTick(()=>{
                        isLoading.value = false;
                    })
                });
            }
        });

        // 檢查特約
        watch(
            () => values.special_select_name,
            (new_value, old_value) => {
                if (new_value === undefined) {
                    values.special_price = 0;
                }

                if (new_value != old_value && new_value) {
                    let specialAry = checkoutData.data.specialAry.reduce((base, item) => {
                        return { ...base, [item.id]: item };
                    }, {});
                    values.special_price = specialAry[new_value].price;
                }
            }
        );

        // 計算服務結束時間及更換時間
        watch([() => values.start_time, () => values.service, () => values.external], (newArr, oldArr) => {
            const [time, service, external] = newArr;
            const [oldTime, oldService, oldExternal] = oldArr;

            if (!time || !oldTime || time.length !== 5) return;

            if (
                time !== oldTime ||
                (service !== undefined && service !== oldService) ||
                (external !== undefined && external !== oldExternal)
            ) {
                countEndTime(1);
            }
        });

        // 計算整筆訂單的金額
        watch(values, () => {
            countMoney();
        });

        const isCheckoutDataEmpty = computed(() => Object.keys(checkoutData.data).length);

        // 加強部位
        const { orderLocation, orderLocationChangeNum, saveLocation } = useOrderLocation(orderId);

        // 增加服務項目
        const addService = () => {
            let first_service = checkoutData.data.serviceAry[0];
            push({
                service_id: first_service.id,
                type: 2,
                txt: first_service.price,
                is_old: 0,
            });
        };

        // 增加外部券
        const addExternal = () => {
            let first_external = externalList.data[0];
            pushExternal({
                external_id: first_external.id,
                type: 3,
                external_number: '',
                list: first_external.services.map((item) => {
                    return { ...item, is_old: 0, service_id: item.id };
                }),
            });
        };

        // 更換選項時
        const changeService = (idx) => {
            let services = checkoutData.data.serviceAry.reduce((lookup, item) => {
                return {
                    ...lookup,
                    [item.id]: item,
                };
            }, {});
            if (values.service[idx].type == 2) {
                values.service[idx].txt = services[values.service[idx].service_id].price;
            }

            countEndTime(1);
        };

        // 更換外券選項時
        const changeExternal = (idx) => {
            const thisExternal = values.external[idx];
            const list = externalList.data.find((item) => {
                return item.id === thisExternal.external_id;
            });
            values.external[idx].list = list.services.map((item) => {
                return { ...item, is_old: 0, service_id: item.id };
            });
            countEndTime(1);
        };

        const paymentMethod = computed(() => {
            return checkoutData.data.payment_method.map((item) => ({
                id: item.key,
                name: item.name,
            }));
        });

        const initDiscountPrice = () => {
            return {
                name: '',
                price: 0,
                msg: '',
            };
        };

        let discountPrice = reactive(initDiscountPrice());
        const verifyDiscountNum = async () => {
            if (values.discount_number_list[0] === '') {
                discountPrice.msg = '';
                discountPrice.name = '';
                discountPrice.price = 0;
                return;
            }
            try {
                const data = await getDiscountPrice(values.discount_number_list[0]);
                discountPrice.price = data.price;
                discountPrice.name = data.discount_name;
                discountPrice.msg = '';
            } catch (data) {
                discountPrice.price = 0;
                discountPrice.name = '';
                discountPrice.msg = data.res_content;
            }
        };
        // 計算整筆訂單的金額
        watch(discountPrice, () => {
            countMoney();
        });

        watch(isShow, (isShow) => {
            if (!isShow) {
                Object.assign(discountPrice, initDiscountPrice());
            }
        });

        const { mixService, getGoodAtService } = useAromaList(storeId, 'room_add');

        const serviceIdList = computed(()=>{
            if(!values) return [];
            return mixService(values.service, values.external)
            .map(item => Number(item.service_id))
            .filter((item, index, arr) => {
                return arr.indexOf(item) === index;
            })
        });

        const aromaList = computed(()=>{
            return checkoutData.data.memberAry
        });

        const getAromaList = async (arr)  => {
            try {
                const res = await getGoodAtService(arr, orderId.value || null);
                aroma.data = res;
            } catch (err) {
                console.error(err);
            }
        }
        
        watch(serviceIdList, (serviceIdList) => {
            if(!isShow.value) return;
            if(!serviceIdList.length) return;
            if(isLoading.value) return;
            getAromaList(serviceIdList);
        })

        const aroma = reactive({
            data: null,
            list: computed(() => {
                return aroma.data ?
                    aroma.data.filter(item => item.is_punch == 1) :
                    aromaList.value
            })
        });
        // 關掉的時候初始化
        watch(isShow, (isShow) => {
            if (!isShow) {
                aroma.data = null;
            }
        });

        const ticketList = computed(()=>{
            if(!values) return [];
            return [...(values.service || [])]
        });

        //  只給watch判斷是否需要重取小計
        const watchService = computed(()=>{
            if(!values) return [];
            return [...(values.service || [])].map(item => {
                return {
                    service_id: item.service_id,
                }
            })
        });

        watch(()=> watchService, ()=> {
            if(!isShow.value) return;
            getTicketList();
        }, { deep: true })

        // 關掉的時候初始化
        watch(isShow, (isShow) => {
            if (!isShow) {
                replace([]);
            }
        });

        // 取得小計
        const getTicketList = async () => {
            const serviceContent = ticketList.value
            const res = await basePost('/api_order/calculate_ticket_sales_service_spread', {
                store_id: storeId,
                service_content: JSON.stringify(serviceContent),
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                smAmount.value = data.res_data.amount;
            }else {
                flashMessage(data.res_content, 'error');
            }
            countMoney();
        };

        // 20231110 進行中訂單
        const getQRCode = async (obj) => {
            try {
                const res = await basePost('/api_order/get_order_qrcode', {
                    store_id: storeId.value,
                    ...obj
                });

                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    qrCode.img =  data.res_data.qr_code_img
                    qrCode.url = data.res_data.url
                }
            } catch (err) {
                console.error(err);
            }
        };

        const qrCode = reactive({
            isShow: false,
            img: '',
            url: '',
        })

        function openQrCode() {

            getQRCode({
                order_id : orderId.value
            }).then(()=>{
                qrCode.isShow = true
            })

        }

        const handlePrintWhiteList=(event)=>{
            event.preventDefault();
            Swal.fire({
                title: "是否列印小白單?",
                showCancelButton: true,
                confirmButtonText: "列印",
                cancelButtonText: "不列印",
            }).then((result) => {
                // console.log('orderId.value' , orderId.value);
                if (result.value) {
                    dispatch('ticket/getWhiteListDetailsHtml', orderId.value);
                }
            });
        }

        // 確認是否發送簡訊
        const sentSms = () =>{
            Swal.fire({
                title: "是否發送加強部位設定簡訊給客人?",
                showCancelButton: true,
                confirmButtonText: "確認",
                cancelButtonText: "取消",
            }).then((result) => {
                if (result.value) {

                    basePost('/api_order/set_order_sms', {
                        order_id : orderId.value
                    }).then((res) => {
                        const { status, data } = res;
                        
                        if (status === 200) {
                            Swal.fire({
                                title: `${data.res_content}!`,
                                confirmButtonText: "確認",
                            })    
                        }else{
                            Swal.fire({
                                title: "簡訊發送失敗!",
                                confirmButtonText: "確認",
                            })  
                        }

                    }).catch((err) => {
                        console.error(err);
                    });    
                }
            });       
        }

        const isInputCompleted = ref(false);

        const inputCheck = (e) => {
            const customerPhone = document.querySelector('.customer_phone_text');
            const customerName = document.querySelector('.customer_name_text');

            isInputCompleted.value = customerPhone.value !== '' && customerName.value !== '';

            if(isInputCompleted.value == false){
                Swal.fire('請填寫會員電話及會員名稱!');
                return;
            }else{
                console.log('填寫完畢')
                const clickedElement = e.target.closest('.qrcode_icon, .button-sms');

                if (clickedElement && clickedElement.classList.contains('qrcode_icon')) {
                    openQrCode();
                } 
                else {
                    sentSms();
                }
            }
        };


        return {
            isShow,
            openCheckoutLightbox,
            closeCheckoutLightbox,
            memberSide,
            CloseTab,
            isSubmitting,
            checkoutData,
            orderLocation,
            fields,
            isCheckoutDataEmpty,
            values,
            saveLocation,
            finalNum,
            toSubmit,
            smAmount,
            checkMember,
            addService,
            remove,
            changeService,
            externalList,
            addExternal,
            removeExternal,
            changeExternal,
            fieldsExternal,
            paymentMethod,
            discountPrice,
            verifyDiscountNum,
            bedList,
            serviceIdList,
            aroma,
            toSubmitRoom,
            getTicketList,
            isFetching,
            numberFormat,
            timeFormat,
            // 20231110 進行中訂單
            openQrCode,
            qrCode,
            handlePrintWhiteList,
            isOnSiteRoom,
            sentSms,
            isInputCompleted,
            inputCheck,
            hideSaveBtn,
            customSwal
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/css/reset';
@import '~@/css/mixins';
@import '~@/css/grid';
.slide-enter-active,
.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}

$side-menu-width: 640px;
.checkout-lightbox {
    position: fixed;
    top: 0;
    right: 0;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            box-shadow: -3px -4px 41px #2e2e2e5e;
            position: absolute;
            z-index: 3;
            overflow: hidden;
            .col-4 {
                padding-left: 0;
                flex: 0 0 40%;
                max-width: 40%;
            }
            .col-5 {
                padding-left: 0;
                flex: 0 0 47%;
                max-width: 47%;
            }
            .col-7 {
                padding-right: 0;
                padding-left: 0;
                flex: 0 0 53%;
                max-width: 53%;

                .col-6 {
                    padding-right: 0;
                    padding-left: 0;
                    select {
                        width: 50%;
                        padding-right: 31px !important;
                    }
                }
            }
            .col-8 {
                padding-right: 0;
                padding-left: 0;
                flex: 0 0 60%;
                max-width: 60%;
            }
            .col-12 {
                padding-right: 0;
                padding-left: 0;
            }
        }
        .side-menu-top {
            background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0% no-repeat
                padding-box;
            .side-menu-item {
                padding-top: 45px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #eee;

                label {
                    input[type='checkbox'] {
                        display: none;
                    }
                    input[type='checkbox'] + span {
                        display: inline-block;
                        padding-left: 38px;
                        line-height: 28px;
                        background: url('~@/assets/tgd/lightbox/radio-none.svg') no-repeat;
                        user-select: none;
                    }
                    input[type='checkbox']:checked + span {
                        background: url('~@/assets/tgd/lightbox/radio-checked.svg') no-repeat;
                    }
                }
                .col-7,
                .col-8,
                .col-12 {
                    position: relative;
                    .cancel-img {
                        position: absolute;
                        right: -40px;
                        top: 50%;
                        transform: translate(0%, -57%);
                        img {
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                    }
                    .cash-wrap,
                    .external-info-wrap {
                        width: calc(100% - 70px);
                        padding-right: 10px;
                        display: inline-block;
                        :deep(.form-control.border-radius-all) {
                            border-radius: 11px !important;
                        }
                    }
                    .is-old {
                        display: inline-block;
                    }
                }
                .title-group {
                    display: flex;
                    align-items: center;
                    label {
                        padding-left: 15px;
                        span {
                            font: normal normal bold 16px/21px Microsoft JhengHei;
                            letter-spacing: 0px;
                            color: #000000;
                            padding-left: 10px;
                        }
                    }
                    .red {
                        color: #d94343;
                    }
                }
                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                }
                .form {
                    .input-group {
                        box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        height: 53px;
                        .input-group-text {
                            background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                                no-repeat padding-box;
                            border: none;
                            border-radius: 11px 0px 0px 11px;
                            padding: 15px;
                            position: relative;
                            font-weight: bold;
                            &:after {
                                content: '';
                                position: absolute;
                                border-right: 1px solid #eee;
                                top: 10px;
                                bottom: 10px;
                                right: 0;
                                z-index: 1;
                            }
                            .select-coupon {
                                border-radius: 11px 0px 0px 11px;
                                padding-right: 32px;
                                height: 100%;
                                color: #0f2a68;
                                background: #f9f9f9 url('~@/assets/tgd/lightbox/box1-arrow1.svg') no-repeat 90%;
                            }

                            .select-money {
                                border-radius: 11px 0px 0px 11px;
                                padding-right: 32px;
                                height: 100%;
                                color: #a4641a;
                                background: #f9f9f9 url('~@/assets/tgd/lightbox/box1-arrow2.svg') no-repeat 90%;
                            }
                        }
                        .end-text {
                            color: rgba(0, 0, 0, 0.5);
                        }
                        .form-control {
                            background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                                no-repeat padding-box;
                            border: none;
                            border-radius: 0px 11px 11px 0px;
                            padding: 14px;
                            font: normal normal 600 16px/22px Open Sans;
                        }
                        select {
                            flex: 1;
                            background: #f9f9f9 url('~@/assets/tgd/lightbox/select-arrow-box.svg') no-repeat 101%;
                            border: none;
                            border-radius: 11px;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                            appearance: none;
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                        .custom-rounded {
                            border-radius: 0px 11px 11px 0px;
                        }
                        .btn {
                            width: 100%;
                            border-radius: 11px;
                            padding: 15px;
                            background: transparent
                                linear-gradient(282deg, #a4641a 0% #b57831 36%, #a4641a 88%, #ebc496 100%);
                            box-shadow: 0px 3px 6px #00000029;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border: 1px solid #ffffff85;
                            p {
                                width: 19.6px;
                            }
                            &.violation {
                                background: transparent
                                    linear-gradient(282deg, #481e00 0%, #9c5828 36%, #cf9064 88%, #8f5126 100%);
                            }
                        }
                    }
                }
            }
        }
        .side-menu-bottom {
            background: #e1e1e1;
            .side-menu2-item {
                padding: 25px 0;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #ccc;
                label {
                    margin-bottom: 10px !important;
                    margin-right: 10px !important;
                    input[type='radio'] {
                        display: none;
                    }
                    input[type='radio'] + span {
                        display: inline-block;
                        padding-left: 38px;
                        line-height: 28px;
                        background: url('~@/assets/tgd/lightbox/radio-none.svg') no-repeat;
                        user-select: none;
                    }
                    input[type='radio']:checked + span {
                        background: url('~@/assets/tgd/lightbox/radio-checked.svg') no-repeat;
                        color: #a4641a;
                    }
                    span {
                        font-size: 16px;
                    }
                }
                .sum {
                    letter-spacing: 0px;
                    color: #4a4a4a;
                    .col-5 {
                        font: normal normal bold 18px/24px Microsoft JhengHei;
                    }
                    .col-7 {
                        font: normal normal bold 20px/27px Open Sans;
                        text-align: right;
                    }
                }

                .form {
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    .row {
                        align-items: center;
                        padding-bottom: 10px;
                        .last-color {
                            color: #d94343;
                            font: normal normal 600 20px/22px Open Sans;
                        }
                        .fz-20 {
                            font-size: 20px;
                        }
                        .input-group {
                            border-radius: 11px;
                            height: 53px;
                            .input-group-text {
                                background: #f6f6f6;
                                border: none;
                                border-radius: 11px 0px 0px 11px;
                                padding: 15px;
                                font-weight: bold;
                                width: 100%;
                                &:after {
                                    display: none;
                                }
                            }
                            .money-text {
                                color: #a4641a;
                            }
                            .select-icon {
                                font-weight: blod;
                                color: #a4641a;
                                font-size: 30px;
                                text-align: center;
                            }

                            .form-control {
                                background: #f6f6f6;
                                border: none;
                                border-radius: 0px 11px 11px 0px;
                                padding: 15px;
                                font: normal normal 600 20px/22px Open Sans;
                                text-align: right;
                            }
                            .custom-radius {
                                border-radius: 11px;
                            }
                            select {
                                flex: 1;
                                background: #f6f6f6 url('~@/assets/tgd/lightbox/box2-arrow.svg') no-repeat 95%;
                                border: none;
                                border-radius: 11px 0px 0px 11px;
                                padding: 15px;
                                padding-right: 30px;
                                font: normal normal 600 16px/22px Open Sans;
                                appearance: none;
                                filter: contrast(100%);
                                transition: all 0.3s;
                                &:hover {
                                    filter: contrast(130%);
                                }
                            }
                            .btn {
                                width: 100%;
                                height: 70px;
                                border-radius: 11px;
                                padding: 15px;
                                box-shadow: 0px 3px 6px #00000029;
                                color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font: normal normal bold 18px/24px Microsoft JhengHei;
                                letter-spacing: 0px;
                                span {
                                    position: relative;
                                    top: 2px;
                                }
                                .price {
                                    display: flex;
                                    align-items: center;
                                    font: normal normal 600 16px/22px Open Sans;
                                    strong {
                                        font: normal normal bold 29px/39px Open Sans;
                                        letter-spacing: 0px;
                                        padding-left: 20px;
                                    }
                                }
                            }
                            .btn-cancel {
                                background: #a2a2a2 0% 0% no-repeat padding-box;
                                justify-content: center;
                            }
                            .btn-checkout {
                                background: #d94343;
                            }
                            .btn-checkout2 {
                                background: transparent linear-gradient(180deg, #464646 0%, #4a4a4a 56%, #4a4a4a 100%)
                                    0% 0% no-repeat padding-box;
                            }
                        }
                        .radio-wrap {
                            box-sizing: border-box;
                            padding-top: 10px;
                        }
                        :deep(.authorization-input) {
                            margin-bottom: 0 !important;
                            box-shadow: none !important;
                        }
                    }
                }
            }
        }
    }
}

.violation {
    :deep(.base-select) {
        .arrow-icon {
            background: transparent linear-gradient(1deg, #cf9064 0%, #9c5828 61%, #81471d 100%) 0 0 no-repeat;
        }
    }
}
.input-wrap {
    position: relative;
    span {
        font-size: 12px;
        color: #f82e2e;
        position: absolute;
        left: 0;
        top: 85%;
    }
}

.btn-order{
    border:0;
    outline:0;
    margin-left:5px;
}

.qrcode_wrap{
    display: inline-flex;
    align-items: center;
    .button-sms{
        margin-left: 10px;
    }
}
</style>
